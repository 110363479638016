:root{
  --color-red:#e54545;
  --color-green:#48dcc0;
  --color-orange:#ff9d5a;
  --color-yellow:#ffe75a;
  --color-green33:#158693;
  --color-green35:#00b0b2;
  --color-green42:#00d897;
  --color-blue:#3384e1;
  --color-blue15:#052a45;
  --color-blue13:#05243b;
  --color-blue9:#001b30;
  --color-gray96:#f3f6f7;
  --color-neutral-dark:#666e76;
  --color-neutral-light:#9ba6aa;
  --color-bg:var(--color-gray96);
  --color-panel:#fff;
  --color-text:#454f54;
  --color-link:var(--color-green35);
  --color-divider:#e7ecef;
  --color-input-border:#ced5dc;
  --color-input-border-focus:var(--color-green35);
  --color-charcoal:#4e595f;
  --color-salt:#fff;
  --color-pepper:#818b92;
  --color-mint:#00b0b3;
  --color-grey100:#f3f6f7;
  --color-grey200:#e7ecef;
  --color-grey300:#cfd6dd;
  --color-grey400:#bfc9cf;
  --color-lettuce:#47dcc1;
  --color-tomato:#e44444;
  --color-mustard:#ffd147;
  --color-water:#3384e1;
  --color-dark-mint:#158793;
  --color-orange:#ff9d5c;
  --bp-small:767px;
  --bp-medium:768px;
  --bp-large:992px;
  --bp-xlarge:1367px;
  --font-sans-serif:geomanist, Arial;
  --font-size-base:.875rem;
  --font-weight:500;
  --font-size-1:2rem;
  --line-height-1:1.3125;
  --font-size-2:1.5rem;
  --line-height-2:1.3333;
  --font-size-3:1.25rem;
  --line-height-3:1.3;
  --font-size-4:1rem;
  --line-height-4:1.5;
  --font-size-5:var(--font-size-base);
  --line-height-5:1.5714;
  --font-size-body:var(--font-size-base);
  --line-height-body:1.5714;
  --font-size-small:.75rem;
  --line-height-small:1.3333;
  --space-unit:0.5rem;
  --space-xs:var(--space-unit);
  --space-s:calc(2*var(--space-unit));
  --space-m:calc(3*var(--space-unit));
  --space-l:calc(4*var(--space-unit));
  --space-xl:calc(6*var(--space-unit));
}
.lumi--header{
  height:72px;
  box-shadow:0 .125rem .5625rem 0 #e7ecef;
  box-shadow:0 .125rem .5625rem 0 var(--color-grey200);
  background-color:#fff;
  background-color:var(--color-salt);
  font-size:1rem;
  line-height:1.5;
}
.lumi--header,.lumi--header-brand{
  display:-webkit-box;
  display:flex;
}
.lumi--header-brand{
  border-right:.0625rem solid #e7ecef;
  border-right:.0625rem solid var(--color-grey200);
  padding:0 1rem;
  -webkit-box-align:stretch;
          align-items:stretch;
}
.lumi--header-brandselector,.lumi--header-logotext{
  padding-left:.5em;
}
.lumi--header-brandselector{
  height:100%;
  padding:.375rem 2.1875rem .1875rem 0;
  color:#454f54;
  color:var(--color-text);
  background-repeat:no-repeat;
  background-position:right 8px top 55%;
  background-image:url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMiIgaGVpZ2h0PSI3Ij48cGF0aCBmaWxsPSJub25lIiBzdHJva2U9IiM5QkE2QUEiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIgc3Ryb2tlLXdpZHRoPSIyIiBkPSJNMSAxLjEyMkw1LjkzOCA2IDExIDEiLz48L3N2Zz4=");
}
.lumi--header-brandselector:focus{
    outline:none;
  }
.lumi--header-logo{
  display:-webkit-box;
  display:flex;
  padding:.375rem .5rem .1875rem;
  -webkit-box-align:center;
          align-items:center;
  color:#4e595f;
  color:var(--color-charcoal);
}
.lumi--header-logo:hover{
    text-decoration:none;
  }
.lumi--header-logo>img{
  max-height:38px;
}
.lumi--header-nav{
  padding:0 .375rem;
}
.lumi--header-nav>ul{
  list-style:none;
  display:-webkit-box;
  display:flex;
  height:100%;
}
.lumi--header-navitem{
  padding:0 .625rem;
}
.lumi--header-navitem>a{
  display:-webkit-box;
  display:flex;
  height:100%;
  -webkit-box-align:center;
          align-items:center;
  padding:.375rem .625rem 0;
  border-bottom:.1875rem solid transparent;
  color:#666e76;
  color:var(--color-neutral-dark);
}
.lumi--header-navitem>a:hover{
    text-decoration:none;
  }
.lumi--header-navitem.is-active>a{
  border-bottom-color:#00b0b3;
  border-bottom-color:var(--color-mint);
  font-weight:500;
  color:#4e595f;
  color:var(--color-charcoal);
}
.lumi--header-meta{
  margin:0 1rem 0 auto;
  display:-webkit-box;
  display:flex;
  -webkit-box-align:center;
          align-items:center;
}
.lumi--header-metabutton{
  width:44px;
  height:44px;
  padding:0;
  margin-left:.25rem;
}
.lumi--header-metabutton:focus{
    outline:none;
  }
.lumi--header-avatar{
  width:32px;
  height:32px;
}
